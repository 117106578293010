<template>
  <section class="profile__data">
    <table>
      <thead>
        <tr>
          <th><h4 class="first">Логотип</h4></th>
          <th colspan="3"><h4>Реквизиты</h4></th>
          <th><h4 class="first">Документы</h4></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td rowspan="3" class="profile__data--logo">
            <div class="profile__logo">
              <img
                v-if="this.userData.logo64"
                :src="this.userData.logo64"
                alt="Логотип"
              />
              <img
                v-else
                style="height: 24px; width: 24px"
                src="../../assets/images/no_logo.svg"
                alt="Нет логотипа"
              />
            </div>
          </td>
          <td class="profile__up profile__fix">
            <h5>Название компании:</h5>
            <p>{{ userData.name }}</p>
          </td>
          <td class="profile__up">
            <h5>ИНН:</h5>
            <p>{{ userData.inn }}</p>
          </td>
          <td class="profile__up">
            <h5>Кор. счёт:</h5>
            <p>{{ userData.corScore }}</p>
          </td>
          <td rowspan="3" class="profile__data--docs">
            <div class="profile__docs">
              <div class="profile__docs--grid">
                <div
                  v-for="docItem in nameDocs"
                  :key="docItem.docFile"
                  class="grid__element"
                >
                  <div>
                    <img
                      src="../../assets/images/docs-yes.svg"
                      :alt="docItem.docName"
                    />
                  </div>
                  <div>
                    <h4>{{ docItem.docName }}</h4>
                    <a
                      href="#"
                      @click.prevent="
                        downloadFileServer(docItem.docId, docItem.docFile)
                      "
                      :title="`${docItem.docName} посмотреть`"
                      >Скачать</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td class="profile__up profile__fix">
            <h5>Полное название компании:</h5>
            <p>{{ userData.fullName }}</p>
          </td>
          <td class="profile__up">
            <h5>КПП:</h5>
            <p>{{ userData.kpp }}</p>
          </td>
          <td class="profile__up">
            <h5>Расчётный счёт:</h5>
            <p>{{ userData.paymentScore }}</p>
          </td>
        </tr>
        <tr>
          <td class="profile__up profile__fix">
            <h5>Юридический адрес:</h5>
            <p>{{ userData.officialAddress }}</p>
          </td>
          <td class="profile__up">
            <h5>БИК:</h5>
            <p>{{ userData.bic }}</p>
          </td>
          <td class="profile__up">
            <h5>Наименование банка:</h5>
            <p>{{ userData.bankName }}</p>
          </td>
        </tr>
      </tbody>
    </table>
  </section>
</template>

<script>
// Store
import { mapState } from "vuex";

export default {
  name: "ProfileData",
  mounted() {
    document.querySelector("body").style = "overflow-y: auto";
  },
  computed: {
    ...mapState(["userData", "serverPath", "token"]),
    // Переделаем документы, уберем не загруженные
    nameDocs() {
      return this.userData.clientDocs.filter((item) => item.docFile !== null);
    }
  },
  methods: {
    // Скачать реестр
    async downloadFileServer(id, filename) {
      this.preloader = true;
      await this.axios
        .get(`/api/client/doc/get/${id}`, {
          headers: { Authorization: `Bearer ${this.token}` }
        })
        .then((response) => {
          const link = document.createElement("a");
          link.href = response.data.response;
          link.download = filename;
          link.click();
          // URL.revokeObjectURL(link.href);
        })
        .catch((e) => {
          if (e.response.status == 403) {
            this.errorServer("Не хватает прав");
          } else {
            this.errorServer("Ошибка скачивания файла");
            this.errorAxiosServerSend(e, this.downloadFile, "downloadFile");
          }
        })
        .finally(() => {
          this.preloader = false;
        });
    }
  }
};
</script>

<style lang="scss">
.profile__data {
  margin: -5px 0 14px 0;

  table {
    font-size: 12px;
    width: auto !important;

    tr:hover td {
      background: none !important;
      cursor: inherit !important;
    }

    th,
    td {
      border: none !important;
      text-align: left;
    }

    td {
      height: 44px !important;
      padding-right: 54px;

      &.profile__up {
        vertical-align: top;
      }

      &.profile__fix {
        min-width: 350px;
        width: 350px;
      }
    }
  }

  h4 {
    font-size: 12px;
    margin-bottom: 21px;

    &.first {
      margin-left: 24px;
    }
  }

  h5 {
    font-size: 12px;
    font-weight: 500;
  }

  &--logo {
    width: 160px;

    .profile__logo {
      @include flex;
      border: 1px dashed $color-grey-light;
      border-radius: 8px;
      height: 104px;
      margin-left: 16px;
      overflow: hidden;
      width: 104px;

      img {
        text-align: center;
        width: 100%;
      }
    }
  }

  &--docs {
    .profile__docs {
      border-left: 1px solid $color-grey-light;
      padding: 18px;
      width: 100%;

      &--grid {
        color: $color-basic;
        display: grid;
        font-size: 12px;
        grid-template-columns: 50% 50%;
        grid-template-rows: 50% 50% 50%;
        min-height: 120px;
        min-width: 500px;

        .grid__element {
          @include flex;
          @include flex-align-start;
          @include flex-content-start;
          grid-column: span 1;
          grid-row: span 1;

          img {
            margin-right: 8px;
          }

          h4 {
            font-size: 14px;
            font-weight: 500;
            margin: 0 0 9px 0;
          }

          a {
            color: $color-blue-dark;
            text-decoration: underline;
            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}
</style>
