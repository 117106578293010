<template>
  <section
    v-if="getTarifTable"
    class="profile__tarif"
    style="padding-bottom: 20px"
  >
    <!-- <div
      class="profile__tarif--action"
      :style="`width: ${getTarifTable.width}px`"
    >
      <a href="#">Распечатать</a>
      <a href="#">Отправить по почте</a>
    </div> -->
    <table :style="`width: ${getTarifTable.width}px`">
      <thead>
        <tr>
          <th
            v-for="(cell, idx) in getHeadFirst"
            :key="idx"
            :rowspan="cell.rowspan"
            :colspan="cell.colspan"
            :class="idx === 0 ? `personal__border` : ``"
            :style="
              cell.colspan === 2
                ? `border-bottom: none`
                : `height: 57px; width: ${cell.width}px`
            "
          >
            {{ cell.all ? cell.all : `` }}
            {{ !cell.all ? cell.name : `` }}
          </th>
        </tr>
        <tr>
          <th
            v-for="cell in getHeadSecond"
            :key="cell.id"
            :style="`height: 20px; width: ${cell.width}px`"
          >
            {{ cell.name }}
          </th>
        </tr>
      </thead>
      <tbody v-if="userData.clientRates">
        <tr
          :class="rates.id === activeTarif ? `active` : ``"
          v-for="rates in userData.clientRates"
          :key="rates.id"
        >
          <td v-for="cell in getTarifTableCell" :key="cell.id">
            {{ rates[cell.id] }}
          </td>
        </tr>
      </tbody>
    </table>
  </section>
</template>

<script>
// Client Tarif Array
import { ClientTarifArray } from "../../data/client-tarif";
// Store
import { mapState } from "vuex";

export default {
  name: "ProfileTarif",
  mounted() {
    document.querySelector("body").style = "overflow-y: auto";
    if (this.$route.query.data === "tarif") {
      this.payment = this.$route.query.payment;
    }
  },
  data() {
    return {
      userTarif: ClientTarifArray,
      payment: ""
    };
  },
  computed: {
    ...mapState(["userData", "activeTarif"]),
    // Данные по таблице
    getTarifTable() {
      let paymentType = this.userData.paymentType || this.payment;
      return this.userTarif.filter((item) => item.value === paymentType)[0];
    },
    getTarifTableCell() {
      return this.getTarifTable.cell;
    },
    getHeadFirst() {
      return this.getTarifTableCell.filter((item) => item.all !== 1);
    },
    getHeadSecond() {
      return this.getTarifTableCell.filter((item) => item.all !== "");
    }
  }
};
</script>
