<template>
  <section class="profile__contacty">
    <contacty-card
      :photo="userData.managerPhoto"
      :name="userData.manager"
      :phone="userData.managerPhone"
      :email="userData.managerEmail"
      title="Мой логист"
    ></contacty-card>
    <contacty-card
      v-if="bossUsers.StaffRTO"
      :photo="bossUsers.StaffRTO.image"
      :name="bossUsers.StaffRTO.fullName"
      :phone="bossUsers.StaffRTO.phone"
      :email="bossUsers.StaffRTO.email"
      title="Руководитель транспортного отдела"
    ></contacty-card>
    <contacty-card
      v-if="bossUsers.StaffFinance"
      :photo="bossUsers.StaffFinance.image"
      :name="bossUsers.StaffFinance.fullName"
      :phone="bossUsers.StaffFinance.phone"
      :email="bossUsers.StaffFinance.email"
      title="Главный бухгалтер"
    ></contacty-card>
  </section>
</template>

<script>
// Карточка
import ContactyCard from "./components/contacty-card";
// Store
import { mapActions, mapState } from "vuex";

export default {
  name: "ProfileContacty",
  components: { ContactyCard },
  data() {
    return {
      bossUsers: []
    };
  },
  created() {
    // Получение данных пользователей из базы
    this.getBossUsers();
  },
  mounted() {
    document.querySelector("body").style = "overflow-y: auto";
  },
  computed: {
    ...mapState(["token", "userData", "serverPath"])
  },
  methods: {
    // Store
    ...mapActions(["REFRESH_TOKEN_HANDLER"]),
    // Получение данных пользователей из базы
    async getBossUsers() {
      await this.axios
        .get("/api/client/staff/get", {
          headers: { Authorization: `Bearer ${this.token}` }
        })
        .then((response) => {
          this.bossUsers = response.data.response;
        })
        .catch((e) => {
          const { code, message } = e.response.data;
          if (code === 401 && message === "Expired JWT Token") {
            this.REFRESH_TOKEN_HANDLER().then(() => {
              this.getBossUsers();
            });
          } else {
            console.error("Ошибка в получении refreshToken" + e.message);
          }
        });
    }
  }
};
</script>

<style lang="scss">
.profile__contacty {
  @include flex;
  @include flex-content-start;
  padding-bottom: 25px;

  &--card {
    border: 1px solid $color-grey-light;
    border-radius: 8px;
    font-size: 12px;
    height: 200px;
    margin-right: 24px;
    padding: 13px 24px;
    width: 296px;

    h4 {
      margin-bottom: 26px;
    }

    .card__content {
      @include flex;
      @include flex-content-start;
    }

    .card__logo {
      @include flex;
      border: 1px solid $color-grey-light;
      border-radius: 50%;
      cursor: pointer;
      height: 72px;
      margin-right: 16px;
      overflow: hidden;
      width: 72px;

      img {
        text-align: center;
        width: 100%;
      }
    }

    .card__text {
      h4 {
        font-size: 16px;
        font-weight: normal !important;
        margin-bottom: 9px;
      }

      p {
        @include flex;
        @include flex-content-start;
        margin-bottom: 10px;

        img {
          margin-right: 8px;
        }
      }
    }
  }
}
</style>
