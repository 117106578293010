<template>
  <div class="main__pages">
    <div v-if="!mounted" class="full__screen">
      <app-loader></app-loader>
    </div>
    <div v-else class="main__content">
      <div class="main__orders">
        <add-order
          :status="userData.status"
          @addNewOrderDrop="addNewOrder"
          @getSecondModal="getSecondModal"
        ></add-order>
        <!-- <div @click="openSecondModalHandler" class="main__bell">
            <svg
              width="17"
              height="21"
              viewBox="0 0 17 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.49935 20.9167C9.65039 20.9167 10.5827 19.9844 10.5827 18.8333H6.41602C6.41602 19.9844 7.34831 20.9167 8.49935 20.9167ZM14.7493 14.6667V9.45832C14.7493 6.2552 13.0462 3.58332 10.0618 2.87499V2.16666C10.0618 1.30207 9.36393 0.604156 8.49935 0.604156C7.63477 0.604156 6.93685 1.30207 6.93685 2.16666V2.87499C3.95247 3.58332 2.24935 6.2552 2.24935 9.45832V14.6667L0.166016 16.75V17.7917H16.8327V16.75L14.7493 14.6667Z"
                fill="#58595B"
              />
            </svg>
          </div> -->
      </div>
      <div class="main__profile">
        <div
          v-for="(page, index) in pageTabs"
          :key="index"
          class="main__profile--block"
          :style="index === 2 ? `margin-bottom: 80px` : ``"
        >
          <div
            @click="CHANGE_PAGE_TAB({ index, open: pageTabs[index].open })"
            class="main__profile--title"
          >
            <h2>
              {{ page.page }}
            </h2>
            <div class="tabs">
              <svg
                v-if="pageTabs[index].open"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.6561 16.2427L19.0703 14.8285L11.9993 7.75737L4.92819 14.8284L6.34241 16.2426L11.9992 10.5858L17.6561 16.2427Z"
                  fill="#58595B"
                />
              </svg>
              <svg
                v-else
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.3439 7.75732L4.92969 9.17154L12.0007 16.2426L19.0718 9.17157L17.6576 7.75735L12.0008 13.4142L6.3439 7.75732Z"
                  fill="#58595B"
                />
              </svg>
            </div>
          </div>
          <div v-if="pageTabs[index].open" class="main__profile--data">
            <keep-alive>
              <profile-data
                v-if="index === 0 && pageTabs[0].open"
              ></profile-data>
              <profile-tarif
                v-if="index === 1 && pageTabs[1].open"
              ></profile-tarif>
              <profile-contacty
                v-if="index === 2 && pageTabs[2].open"
              ></profile-contacty>
            </keep-alive>
          </div>
        </div>
      </div>
    </div>
    <new-order
      v-if="newOrder"
      :newOrderType="newOrderType"
      :currentOrderData="currentOrderData"
      @closeModalOrderHandler="closeModalOrderHandler"
    ></new-order>
    <modal-upload
      :openUploadSecondModal="openUploadSecondModal"
      @getSecondModal="getSecondModal"
    ></modal-upload>
    <modal-email
      :openSecondModalEmail="openSecondModalEmail"
      @getSecondModalEmail="getSecondModalEmail"
    ></modal-email>
  </div>
</template>

<script>
// Preloader
import AppLoader from "../components/ui/app-loader";
// Components
import NewOrder from "../components/order/new-order";
import AddOrder from "../components/main/add-order";
import ModalUpload from "../components/order/modal-upload";
import ModalEmail from "../components/order/modal-email";
// Табы
import ProfileData from "../components/profile/profile-data";
import ProfileTarif from "../components/profile/profile-tarif";
import ProfileContacty from "../components/profile/profile-contacty";
// Store
import { mapState, mapActions } from "vuex";

export default {
  name: "Profile",
  components: {
    AppLoader,
    NewOrder,
    AddOrder,
    ProfileData,
    ProfileTarif,
    ProfileContacty,
    ModalUpload,
    ModalEmail
  },
  data() {
    return {
      mounted: false,
      newOrder: false,
      newOrderType: "",
      openUploadSecondModal: false,
      openSecondModalEmail: false,
      currentOrderData: {}
    };
  },
  created() {
    this.setActivePage();
  },
  mounted() {
    this.mounted = true;
    document.querySelector("body").style = "overflow-y: auto";
  },
  computed: {
    ...mapState(["page", "pageTabs", "activeTarif", "userData"])
  },
  methods: {
    ...mapActions([
      "CHANGE_ACTIVE_PAGE",
      "CHANGE_PAGE_TAB",
      "CHANGE_ACTIVE_TARIF"
    ]),
    // Текущая активная страница
    setActivePage() {
      this.CHANGE_ACTIVE_PAGE("profile");
      if (this.$route.query.data === "tarif") {
        this.CHANGE_PAGE_TAB({ index: 0, open: true });
        this.CHANGE_PAGE_TAB({ index: 1, open: false });
        this.CHANGE_PAGE_TAB({ index: 2, open: true });
        this.CHANGE_ACTIVE_TARIF(+this.$route.query.tarif);
      }
    },
    // Создание нового заказа
    addNewOrder(value) {
      this.newOrder = true;
      this.newOrderType = value;
    },
    // Закрытие модального окна, очистка данных
    closeModalOrderHandler() {
      this.newOrder = false;
      this.newOrderType = "";
    },
    // Закрываем второе окно загрузки
    getSecondModal(value) {
      this.openUploadSecondModal = value;
    },
    // Открываем второе окно загрузки почты
    openSecondModalHandler() {
      this.openSecondModalEmail = true;
    },
    // Закрываем второе окно добавления почты
    getSecondModalEmail(value) {
      this.openSecondModalEmail = value;
    }
  }
};
</script>
