<template>
  <div v-if="openSecondModalEmail" class="order__modal">
    <div
      class="modal__second"
      :class="openSecondModalEmail ? `slide__bottom` : `slide__top`"
      ref="newSecondModalEmail"
    >
      <div class="modal__second--big">
        <div class="modal__second--close">
          <img
            @click="closeSecondModalEmail"
            src="../../assets/images/close.svg"
            alt="Закрыть"
          />
        </div>
        <section v-if="modalAddEmail">
          <div
            v-if="!requestEmailArray.length"
            style="margin: 0 auto; width: 400px"
          >
            <h1>Укажите электронную почту</h1>
            <div style="font-size: 20px; margin: -26px 0 40px 0">
              на нее мы будем прислать письма<br />с изменением статуса заказа
            </div>
            <form-input
              v-model="requestEmail"
              type="email"
              placeholder="Введите почту"
              style="margin: 0 auto; width: 264px"
              @input="requestEmailData"
            ></form-input>
          </div>
          <div
            v-else
            style="
              margin: 58px auto 0 auto;
              max-height: 240px;
              overflow-y: auto;
              padding-bottom: 25px;
              width: 420px;
            "
          >
            <div
              v-for="(itemEmail, idx) in requestEmailArray"
              :key="itemEmail.id"
              class="item__email"
            >
              {{ itemEmail.email }}
              <img
                @click="requestEmailRemove(idx, itemEmail.id)"
                class="item__email--close"
                src="../../assets/images/close.svg"
                alt="Удалить"
              />
            </div>
            <div v-if="!addEmail" @click="addNewEmailField" id="email__add">
              <img
                src="../../assets/images/modal-order/plus-circle.svg"
                alt="Добавить"
              />
              Добавить электронный адрес
            </div>
            <form-input
              v-else
              v-model="requestEmail"
              type="email"
              placeholder="Введите почту"
              style="margin: 0 auto; width: 264px"
              :isInvalid="emailValidation"
              :invalidText="invalidText"
              @input="requestEmailData"
            ></form-input>
            <div ref="addNewEmail"></div>
          </div>
        </section>
        <div
          @click="addNewRequestEmail"
          class="modal__second--btn button__yellow"
          style="bottom: 10px; left: calc(50% - 132px); position: absolute"
          :disabled="!requestEmail"
          :class="!requestEmail ? `disabled` : ``"
        >
          СОХРАНИТЬ
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Store
import { mapState, mapActions } from "vuex";
// Компоненты формы
import FormInput from "../../components/form/form-input";

export default {
  name: "ModalEmail",
  components: { FormInput },
  props: {
    openSecondModalEmail: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      modalAddEmail: false,
      requestEmail: "",
      requestEmailArray: [],
      addEmail: false,
      emailValidation: false,
      invalidText: ""
    };
  },
  created() {
    document.addEventListener("click", this.dropModalEmailSecondHandler);
    this.modalAddEmail = true;
    this.getEmailArray();
  },
  beforeDestroy() {
    document.removeEventListener("click", this.dropModalEmailSecondHandler);
  },
  computed: {
    ...mapState(["token"])
  },
  methods: {
    ...mapActions(["REFRESH_TOKEN_HANDLER"]),
    // Получение массива email клиентов
    async getEmailArray() {
      await this.axios
        .get("/api/client/notification/email/get", {
          headers: { Authorization: `Bearer ${this.token}` }
        })
        .then((response) => {
          this.requestEmailArray = response.data.response;
        })
        .catch((e) => {
          const { code, message } = e.response.data;
          if (code === 401 && message === "Expired JWT Token") {
            this.REFRESH_TOKEN_HANDLER().then(() => {
              this.getEmailArray();
            });
          } else {
            console.error("Ошибка в получении getEmailArray" + e.message);
          }
        });
    },
    // Добавление в массив email клиентов
    async addToEmailArray(email) {
      await this.axios
        .get(`/api/client/notification/email/create/${email}`, {
          headers: { Authorization: `Bearer ${this.token}` }
        })
        .then(() => {
          this.getEmailArray();
        })
        .catch((e) => {
          const { code, message } = e.response.data;
          if (code === 401 && message === "Expired JWT Token") {
            this.REFRESH_TOKEN_HANDLER().then(() => {
              this.addToEmailArray();
            });
          } else {
            console.error("Ошибка в получении addToEmailArray" + e.message);
          }
        });
    },
    // Добавление в массив email клиентов
    async removeToEmailArray(index, id) {
      await this.axios
        .get(`/api/client/notification/email/delete/${id}`, {
          headers: { Authorization: `Bearer ${this.token}` }
        })
        .then(() => {
          this.requestEmailArray.splice(index, 1);
        })
        .catch((e) => {
          const { code, message } = e.response.data;
          if (code === 401 && message === "Expired JWT Token") {
            this.REFRESH_TOKEN_HANDLER().then(() => {
              this.removeToEmailArray();
            });
          } else {
            console.error("Ошибка в получении removeToEmailArray" + e.message);
          }
        });
    },
    // Добавляем новое поле для забивки почты
    addNewEmailField() {
      this.addEmail = !this.addEmail;
    },
    requestEmailData(value) {
      this.requestEmail = value;
    },
    // Добавляем новый email в список
    addNewRequestEmail() {
      if (this.requestEmail) {
        const check = String(this.requestEmail)
          .toLowerCase()
          .match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
        if (!check) {
          this.emailValidation = true;
          this.invalidText = "Введите корректный email";
        } else {
          this.emailValidation = false;
          this.addToEmailArray(this.requestEmail);
          this.addEmail = false;
        }
      }
      this.requestEmail = "";
    },
    // Удалить email из списка
    requestEmailRemove(index, value) {
      if (value) {
        this.removeToEmailArray(index, value).then(() => {
          this.requestEmail = "";
          this.addEmail = false;
        });
      }
    },
    // Закрыть второе модальное окно
    closeSecondModalEmail() {
      this.$emit("getSecondModalEmail", false);
    },
    // Клик не по элементу второго окна
    dropModalEmailSecondHandler(e) {
      let elem = this.$refs.newSecondModalEmail;
      let targetMail = e.target;
      if (elem === targetMail && elem.contains(targetMail)) {
        this.closeSecondModalEmail();
      }
    }
  },
  watch: {
    addNewRequestEmail() {
      location.href = this.$refs.addNewEmail;
    }
  }
};
</script>

<style lang="scss">
.item__email {
  @include flex;
  @include flex-content-start;
  border: 1px solid $color-basic;
  border-radius: 8px;
  color: $color-basic;
  font-size: 16px;
  height: 32px;
  margin: 0 auto 26px auto;
  padding: 0 16px;
  position: relative;
  text-align: left;
  width: 264px;

  &--close {
    cursor: pointer;
    height: 10px;
    position: absolute;
    right: 11px;
    width: 10px;
  }
}

#email__add {
  @include flex;
  cursor: pointer;
  margin: 0 auto;
  width: 400px;
  font-size: 14px;
  font-weight: 400;

  img {
    margin-right: 12px;
  }
}
</style>
