<template>
  <div class="profile__contacty--card">
    <h4>{{ title }}</h4>
    <div class="card__content">
      <div class="card__logo">
        <img @click="openLightbox" :src="photo" alt="Фото" />
        <FsLightbox type="image" :toggler="toggler" :sources="[photo]" />
      </div>
      <div class="card__text">
        <h4>{{ getName }}</h4>
        <p>
          <img src="../../../assets/images/phone.svg" alt="Телефон" />{{
            phone
          }}
        </p>
        <p>
          <img src="../../../assets/images/email.svg" alt="Почта" />
          {{ email }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
// LightBox
import FsLightbox from "fslightbox-vue";

export default {
  name: "ContactyCard",
  props: {
    title: { type: String, default: "" },
    photo: { type: String, default: "" },
    name: { type: String, default: "" },
    phone: { type: String, default: "" },
    email: { type: String, default: "" }
  },
  components: { FsLightbox },
  data() {
    return {
      toggler: false
    };
  },
  computed: {
    getName() {
      const temp = [],
        itemBlock = this.name.trim().split(" ");
      temp[0] = itemBlock[0];
      temp[1] = itemBlock[1];
      return temp.join(" ");
    }
  },
  methods: {
    // Фото пользователя по клику
    openLightbox() {
      this.toggler = !this.toggler;
    }
  }
};
</script>
