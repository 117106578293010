export const ClientTarifArray = [
  {
    value: "stavka",
    // width: 1556,
    width: 1460,
    cell: [
      {
        id: "tonnageMask",
        name: "Тариф",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 200
      },
      // {
      //   id: "tonnage",
      //   name: "Тоннаж",
      //   all: "",
      //   colspan: "",
      //   rowspan: 2,
      //   width: 96
      // },
      {
        id: "boxType",
        name: "Тип кузова",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 146
      },
      {
        id: "minHourStavka",
        name: "Минимально часов в ставке",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 121
      },
      {
        id: "feedVehicle",
        name: "Подача ТС",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 89
      },
      {
        id: "forwarding",
        name: "Экспедирование",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 129
      },
      {
        id: "rateStavka",
        name: "Тариф по ставке",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 121
      },
      {
        id: "processingHour",
        name: "Переработка за час",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 144
      },
      {
        id: "extraPoint",
        name: "Доп. точка",
        all: "Доп. затраты",
        colspan: 2,
        rowspan: "",
        width: 95
      },
      {
        id: "extraCost",
        name: "Стоимость",
        all: 1,
        colspan: 2,
        rowspan: "",
        width: 95
      },
      {
        id: "mkadBorderKm",
        name: "Расчет",
        all: "Километраж за МКАД",
        colspan: 2,
        rowspan: "",
        width: 95
      },
      {
        id: "mkadCost",
        name: "Стоимость",
        all: 1,
        colspan: "",
        rowspan: "",
        width: 95
      },
      {
        id: "DriverLoaderHour",
        name: "Водитель-грузчик за час",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 130
      }
    ]
  },
  {
    value: "clock",
    // width: 1222,
    width: 1126,
    cell: [
      {
        id: "tonnageMask",
        name: "Тариф",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 200
      },
      // {
      //   id: "tonnage",
      //   name: "Тоннаж",
      //   all: "",
      //   colspan: "",
      //   rowspan: 2,
      //   width: 96
      // },
      {
        id: "boxType",
        name: "Тип кузова",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 146
      },
      {
        id: "minHourStavka",
        name: "Минимальный заказ, часов",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 121
      },
      {
        id: "feedVehicle",
        name: "Подача ТС",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 89
      },
      {
        id: "forwarding",
        name: "Экспедирование",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 129
      },
      {
        id: "rateStavka",
        name: "Тариф за час",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 121
      },
      {
        id: "mkadBorderKm",
        name: "Расчет",
        all: "Километраж за МКАД",
        colspan: 2,
        rowspan: "",
        width: 95
      },
      {
        id: "mkadCost",
        name: "Стоимость",
        all: 1,
        colspan: "",
        rowspan: "",
        width: 95
      },
      {
        id: "DriverLoaderHour",
        name: "Водитель-грузчик за час",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 130
      }
    ]
  },
  {
    value: "point",
    // width: 1291,
    width: 1195,
    cell: [
      {
        id: "tonnageMask",
        name: "Тариф",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 200
      },
      // {
      //   id: "tonnage",
      //   name: "Тоннаж",
      //   all: "",
      //   colspan: "",
      //   rowspan: 2,
      //   width: 96
      // },
      {
        id: "boxType",
        name: "Тип кузова",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 146
      },
      {
        id: "minHourStavka",
        name: "Минимальный заказ, точек",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 121
      },
      {
        id: "feedVehicle",
        name: "Подача ТС",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 89
      },
      {
        id: "forwarding",
        name: "Экспедирование",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 129
      },
      {
        id: "amountPoints",
        name: "Кол-во точек",
        all: "Оплата за точку",
        colspan: 2,
        rowspan: "",
        width: 95
      },
      {
        id: "paymentPoint",
        name: "Стоимость",
        all: 1,
        colspan: "",
        rowspan: "",
        width: 95
      },
      {
        id: "mkadBorderKm",
        name: "Расчет",
        all: "Километраж за МКАД",
        colspan: 2,
        rowspan: "",
        width: 95
      },
      {
        id: "mkadCost",
        name: "Стоимость",
        all: 1,
        colspan: "",
        rowspan: "",
        width: 95
      },
      {
        id: "DriverLoaderHour",
        name: "Водитель-грузчик за час",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 130
      }
    ]
  },
  {
    value: "lenght",
    // width: 935,
    width: 839,
    cell: [
      {
        id: "tonnageMask",
        name: "Тариф",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 200
      },
      // {
      //   id: "tonnage",
      //   name: "Тоннаж",
      //   all: "",
      //   colspan: "",
      //   rowspan: 2,
      //   width: 96
      // },
      {
        id: "boxType",
        name: "Тип кузова",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 146
      },
      {
        id: "minKmStavka",
        name: "Расчет",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 121
      },
      {
        id: "rateKm",
        name: "Тариф за КМ",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 121
      },
      {
        id: "rateMinuteWait",
        name: "Тариф за мин. ожидания",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 121
      },
      {
        id: "DriverLoaderHour",
        name: "Водитель-грузчик за час",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 130
      }
    ]
  },
  {
    value: "weight",
    // width: 1222,
    width: 1126,
    cell: [
      {
        id: "tonnageMask",
        name: "Тариф",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 200
      },
      // {
      //   id: "tonnage",
      //   name: "Тоннаж",
      //   all: "",
      //   colspan: "",
      //   rowspan: 2,
      //   width: 96
      // },
      {
        id: "boxType",
        name: "Тип кузова",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 146
      },
      {
        id: "feedVehicle",
        name: "Подача ТС",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 89
      },
      {
        id: "forwarding",
        name: "Экспедирование",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 129
      },
      {
        id: "rateHour",
        name: "Тариф за час",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 121
      },
      {
        id: "rateStavkaKg",
        name: "Тариф по ставке за КГ",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 121
      },
      {
        id: "mkadBorderKm",
        name: "Расчет",
        all: "Километраж за МКАД",
        colspan: 2,
        rowspan: "",
        width: 95
      },
      {
        id: "mkadCost",
        name: "Стоимость",
        all: 1,
        colspan: "",
        rowspan: "",
        width: 95
      },
      {
        id: "DriverLoaderHour",
        name: "Водитель-грузчик за час",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 130
      }
    ]
  },
  {
    value: "sber",
    width: 870,
    cell: [
      {
        id: "tonnageMask",
        name: "Тариф",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 200
      },
      {
        id: "boxType",
        name: "Тип кузова",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 150
      },
      {
        id: "minKmStavka",
        name: "Расчёт",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 130
      },
      {
        id: "rateKmAuto",
        name: "Тариф руб/КМ",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 130
      },
      {
        id: "rateTimeWait",
        name: "Тариф за время ожидания руб/МИН",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 130
      },
      {
        id: "ratePRR",
        name: "Тариф ПРР руб/МИН",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 130
      }
    ]
  }
];
